
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexjzKCSWqrznMeta } from "/app/www-artex-stockexchange-com-frontend/pages/artex-mtf/index.vue?macro=true";
import { default as index7RLEjVka2eMeta } from "/app/www-artex-stockexchange-com-frontend/pages/compliance/index.vue?macro=true";
import { default as index637mrZmZ19Meta } from "/app/www-artex-stockexchange-com-frontend/pages/contact/index.vue?macro=true";
import { default as indexa206nKq8b0Meta } from "/app/www-artex-stockexchange-com-frontend/pages/equities/index.vue?macro=true";
import { default as faqYykHf9l6VeMeta } from "/app/www-artex-stockexchange-com-frontend/pages/faq.vue?macro=true";
import { default as indexBaem4Pyjo9Meta } from "/app/www-artex-stockexchange-com-frontend/pages/how-to-list/index.vue?macro=true";
import { default as index2GznC9LQMKMeta } from "/app/www-artex-stockexchange-com-frontend/pages/index.vue?macro=true";
import { default as indexGOeVhDvHTOMeta } from "/app/www-artex-stockexchange-com-frontend/pages/news/index.vue?macro=true";
import { default as indexGQ7c5Gd1r4Meta } from "/app/www-artex-stockexchange-com-frontend/pages/notices/index.vue?macro=true";
import { default as indexi0xb5mOxe7Meta } from "/app/www-artex-stockexchange-com-frontend/pages/our-company/index.vue?macro=true";
import { default as privacy_45noticeFKjvbsaRFaMeta } from "/app/www-artex-stockexchange-com-frontend/pages/privacy-notice.vue?macro=true";
import { default as indexwaysbKvQ1cMeta } from "/app/www-artex-stockexchange-com-frontend/pages/reporting/index.vue?macro=true";
import { default as terms_45and_45conditionsDyzvn4hjEnMeta } from "/app/www-artex-stockexchange-com-frontend/pages/terms-and-conditions.vue?macro=true";
import { default as indexcoRMLN2bQIMeta } from "/app/www-artex-stockexchange-com-frontend/pages/trading-information/index.vue?macro=true";
import { default as indexJsu5nsGtsBMeta } from "/app/www-artex-stockexchange-com-frontend/pages/trading-participants/index.vue?macro=true";
import { default as indexMwFj0HhyvUMeta } from "/app/www-artex-stockexchange-com-frontend/pages/vcm/index.vue?macro=true";
export default [
  {
    name: "artex-mtf",
    path: "/artex-mtf",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/artex-mtf/index.vue")
  },
  {
    name: "compliance",
    path: "/compliance",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/compliance/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/contact/index.vue")
  },
  {
    name: "equities",
    path: "/equities",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/equities/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/faq.vue")
  },
  {
    name: "how-to-list",
    path: "/how-to-list",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/how-to-list/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/index.vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/news/index.vue")
  },
  {
    name: "notices",
    path: "/notices",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/notices/index.vue")
  },
  {
    name: "our-company",
    path: "/our-company",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/our-company/index.vue")
  },
  {
    name: "privacy-notice",
    path: "/privacy-notice",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/privacy-notice.vue")
  },
  {
    name: "reporting",
    path: "/reporting",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/reporting/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/terms-and-conditions.vue")
  },
  {
    name: "trading-information",
    path: "/trading-information",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/trading-information/index.vue")
  },
  {
    name: "trading-participants",
    path: "/trading-participants",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/trading-participants/index.vue")
  },
  {
    name: "vcm",
    path: "/vcm",
    component: () => import("/app/www-artex-stockexchange-com-frontend/pages/vcm/index.vue")
  }
]