import revive_payload_client_4sVQNw7RlN from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/www-artex-stockexchange-com-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/www-artex-stockexchange-com-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_IJZcPvBsSf from "/app/www-artex-stockexchange-com-frontend/node_modules/nuxt-highcharts/lib/plugin.js";
import plugin_eTVJQYlCmx from "/app/www-artex-stockexchange-com-frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import headScripts_Db9Yza4VR4 from "/app/www-artex-stockexchange-com-frontend/plugins/headScripts.js";
import apollo_1ILFkzpYSr from "/app/www-artex-stockexchange-com-frontend/plugins/apollo.ts";
import recaptcha_W4US4xmBV6 from "/app/www-artex-stockexchange-com-frontend/plugins/recaptcha.js";
import visible_on_scroll_Z45RlpHhPx from "/app/www-artex-stockexchange-com-frontend/plugins/visible-on-scroll.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_IJZcPvBsSf,
  plugin_eTVJQYlCmx,
  headScripts_Db9Yza4VR4,
  apollo_1ILFkzpYSr,
  recaptcha_W4US4xmBV6,
  visible_on_scroll_Z45RlpHhPx
]